export const geoOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

export const getCoordinates = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => reject(error),
        geoOptions,
      );
    } else {
      reject(new Error('Geolocation is not supported by this browser.'));
    }
  });
};

export const getLocationInfo = async (latitude, longitude) => {
  const geocoder = new google.maps.Geocoder();

  const latlng = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
  };

  const response = await geocoder.geocode({ location: latlng });

  if (response.results[0]) {
    const addressComponents = response.results[0]?.address_components;
    const addressComponentsMap = addressComponents.reduce((acc, component) => {
      component.types.forEach((type) => {
        acc[type] = component.long_name;
      });
      return acc;
    }, {});

    return {
      addressLine1: `${addressComponentsMap.street_number || ''} ${
        addressComponentsMap.route || ''
      }`.trim(),
      addressLine2: '',
      townCity: addressComponentsMap.postal_town || '',
      postcode: addressComponentsMap.postal_code || '',
      latitude,
      longitude,
    };
  } else {
    window.alert('No results found');
  }
};
