import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledButton, StyledSpinner } from './styled';

const Spinner = () => (
  <StyledSpinner viewBox='0 0 50 50'>
    <circle
      className='path'
      cx='25'
      cy='25'
      r='20'
      fill='none'
      strokeWidth='3'
    />
  </StyledSpinner>
);

const Button = ({ onClick, loading, children, ...props }) => {
  const { t } = useTranslation();
  return (
    <StyledButton onClick={onClick} {...props}>
      {loading && <Spinner aria-label={t('common.loading')} />}
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default Button;
