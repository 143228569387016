import styled from 'styled-components';

export const Steps = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.default};
  text-align: left;
  padding-bottom: 1rem;
  align-self: flex-start;
`;

export const StepsList = styled.ol`
  margin-top: 0rem;
  list-style: none;
  padding-left: 0;
`;

export const StepsListItem = styled.li`
  display: flex;
  align-items: center; /* Vertically aligns the text and number */
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.black};
  position: relative;
  padding-left: 50px; /* Ensure there's space for the number circle */
  line-height: 3rem; /* Adjust line height for better vertical alignment */

  &::before {
    content: counter(step-counter);
    counter-increment: step-counter;
    background-color: ${({ theme }) => theme.colors.lightGrey};
    color: ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    vertical-align: text-bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    font-size: 16px;
    font-weight: bold;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Link = styled.a`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ theme }) => theme.fontSizes.default};
  color: ${({ theme }) => theme.colors.black};
`;

export const LinkSeparator = styled.span`
  padding-left: 8px;
  padding-right: 8px;
`;
