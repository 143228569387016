import styled from 'styled-components';

export const HeaderContainer = styled.header`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px;
`;

export const Logo = styled.img`
  max-width: 140px;
  max-height: 60px;
  width: auto;
  height: auto;
`;
