import { ArrowRight, StyledButton, TextWrapper } from './styled';

const FullWidthButton = ({ onClick, disabled, children }) => (
  <StyledButton onClick={onClick} disabled={disabled}>
    <TextWrapper disabled={disabled}>{children}</TextWrapper>
    <ArrowRight disabled={disabled} />
  </StyledButton>
);

export default FullWidthButton;
