import PropTypes from 'prop-types';
import { RedoIcon, StyledButton, TextWrapper } from './styled';

const RedoButton = ({ onClick, children, ...props }) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      <TextWrapper>{children}</TextWrapper>
      <RedoIcon />
    </StyledButton>
  );
};

RedoButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default RedoButton;
