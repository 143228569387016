import { Navigate, useLocation } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const location = useLocation();
  const token = location.state?.token;

  if (!token) {
    return <Navigate to='/' replace />;
  }

  return children;
}

export default ProtectedRoute;
