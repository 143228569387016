import styled from 'styled-components';

export const MainContainer = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 0rem;
  padding: 16px;
`;
