import PropTypes from 'prop-types';
import { VideoIcon, StyledButton, TextWrapper } from './styled';

const AddVideoButton = ({ onClick, children, ...props }) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      <VideoIcon />
      <TextWrapper>{children}</TextWrapper>
    </StyledButton>
  );
};

AddVideoButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default AddVideoButton;
