import styled from 'styled-components';
import { Sun, Location, HoldingPhone, Speak } from '../../assets/icons';

export const SunIcon = styled(Sun)`
  height: 32px;
  .fill-selector {
    fill: ${({ theme }) => theme.colors.black};
  }
  margin-right: 16px;
`;

export const LocationIcon = styled(Location)`
  height: 32px;
  .fill-selector {
    fill: ${({ theme }) => theme.colors.black};
  }
  margin-right: 14px;
`;

export const HoldingPhoneIcon = styled(HoldingPhone)`
  height: 32px;
  .fill-selector {
    fill: ${({ theme }) => theme.colors.black};
  }
  margin-right: 23px;
`;

export const SpeakIcon = styled(Speak)`
  height: 32px;
  .fill-selector {
    fill: ${({ theme }) => theme.colors.black};
  }
  margin-right: 16px;
`;

export const GuidelineItem = styled.h3`
  width: 100%;
  padding-top: 24px;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.l};
  align-items: center;
`;

export const GuidelineContainer = styled.div`
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const HiddenInput = styled.input`
  display: none;
`;
