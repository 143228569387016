import styled from 'styled-components';

export const StyledButton = styled.button`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey : theme.colors.yellow};
  color: ${({ theme }) => theme.colors.black};
  padding: 8px 16px;
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
  width: ${({ width }) => width};
  height: 55px;
  border: 2px solid;
  outline: 0px solid
    ${({ hasBlackBackground }) =>
      hasBlackBackground
        ? ({ theme }) => theme.colors.grey
        : ({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.l};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  width: 40px;
  height: 40px;

  & .path {
    stroke: ${({ theme }) => theme.colors.black};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
