import { useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header from '../../components/Header';
import MainContainer from '../../components/MainContainer';
import ScrollToTop from '../../components/ScrollToTop';
import ContentContainer from '../../components/ContentContainer';
import TitleLeft from '../../components/TitleLeft';
import Paragraph from '../../components/Paragraph';

const ThankYouScreen = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { referenceNo, logoSrc } = location.state || {};

  return (
    <>
      <ScrollToTop />
      <Header logoSrc={logoSrc} />
      <MainContainer>
        <ContentContainer>
          <TitleLeft>{t('thankYou.title')}</TitleLeft>
          <Paragraph>{t('thankYou.description')}</Paragraph>
          <Paragraph>
            <Trans
              i18nKey='thankYou.reference'
              values={{ referenceNo }}
              components={{ bold: <strong /> }}
            />
          </Paragraph>
        </ContentContainer>
      </MainContainer>
    </>
  );
};

export default ThankYouScreen;
