// Utility function to convert data URL to Blob
export const dataURLtoBlob = (dataurl) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

const getSquareDimensions = (
  originalWidth,
  originalHeight,
  targetWidth = 400,
  targetHeight = 400,
) => {
  let width = targetWidth;
  let height = targetHeight;

  if (originalWidth > originalHeight) {
    height = (targetWidth / originalWidth) * originalHeight;
  } else {
    width = (targetHeight / originalHeight) * originalWidth;
  }
  return { width, height };
};

export const getThumbnailImage = (videoElement) => {
  const canvas = document.createElement('canvas');

  const originalWidth = videoElement.videoWidth;
  const originalHeight = videoElement.videoHeight;

  // Calculate the aspect ratio and new dimensions to fit within 400x400
  let targetWidth = 400;
  let targetHeight = 400;

  const { width, height } = getSquareDimensions(
    originalWidth,
    originalHeight,
    targetWidth,
    targetHeight,
  );

  canvas.width = width;
  canvas.height = height;

  const context = canvas.getContext('2d');

  context.drawImage(
    videoElement,
    0,
    0,
    originalWidth,
    originalHeight,
    0,
    0,
    targetWidth,
    targetHeight,
  );

  return canvas.toDataURL('image/jpeg', 0.8);
};
