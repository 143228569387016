import { useState, useRef, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProgressHeader from '../../components/ProgressHeader';
import MainContainer from '../../components/MainContainer';
import FullWidthButton from '../../components/FullWidthButton';
import ScrollToTop from '../../components/ScrollToTop';
import ContentContainer from '../../components/ContentContainer';
import TitleLeft from '../../components/TitleLeft';
import Paragraph from '../../components/Paragraph';
import VideoUpload from '../../components/VideoUpload';
import {
  GuidelineItem,
  GuidelineContainer,
  SunIcon,
  LocationIcon,
  HoldingPhoneIcon,
  SpeakIcon,
} from './styled';

const VideoInstructionScreen = () => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState([]);
  const [videoThumbnails, setVideoThumbnails] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (videos.length > 0 && videoThumbnails.length > 0) {
      const video = videos[0];
      const thumbnail = videoThumbnails[0];
      navigate('/video-upload', {
        state: { ...state, video, thumbnail },
      });
    }
  }, [videos, videoThumbnails]);

  const handleAddVideoClick = useCallback(() => {
    if (state.customInstructions) {
      navigate('/custom-video-instructions', { state });
    } else {
      fileInputRef.current.click();
    }
  }, [state.customInstructions]);

  return (
    <>
      <ScrollToTop />
      <ProgressHeader step={1} />
      <MainContainer>
        <ContentContainer>
          <TitleLeft>{t('videoInstructions.title')}</TitleLeft>
          {!state.customInstructions && (
            <VideoUpload
              setVideos={setVideos}
              setVideoThumbnails={setVideoThumbnails}
              inputRef={fileInputRef}
            />
          )}
          <GuidelineContainer>
            <GuidelineItem>
              <SunIcon />
              {t('videoInstructions.guidelines.lighting')}
            </GuidelineItem>
            <GuidelineItem>
              <LocationIcon />
              {t('videoInstructions.guidelines.location')}
            </GuidelineItem>
            <GuidelineItem>
              <HoldingPhoneIcon />
              {t('videoInstructions.guidelines.steady')}
            </GuidelineItem>
            <GuidelineItem>
              <SpeakIcon />
              {t('videoInstructions.guidelines.speak')}
            </GuidelineItem>
          </GuidelineContainer>
          <Paragraph>{t('videoInstructions.warning')}</Paragraph>
        </ContentContainer>
      </MainContainer>
      <FullWidthButton type='button' onClick={handleAddVideoClick}>
        {t('common.next')}
      </FullWidthButton>
    </>
  );
};

export default VideoInstructionScreen;
